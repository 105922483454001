import React from 'react';
import { useParams, Link } from 'react-router-dom';
import MemberCarousel from '../layouts/MemberCarousel';
import styles from '../styles/pages/projectDetails.module.css';

/* Assets */
import case1 from '../assets/buffet.png'; 
import case2 from '../assets/arché.png'; 
import case3 from '../assets/tremkivoa.png'; 
import case4 from '../assets/milhas.png'; 
import case5 from '../assets/img-site-linked.jpg';

const projectData = {
  'site-linked': {
    title: 'Site Linked',
    image: case5,
    fullDescription: 'Site completo para a Linked, conectando profissionais através de portfólios dinâmicos.',
    members: [
      { name: 'André Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg'), github: 'https://github.com/andrechagaslima' },
      { name: 'Messias Feres', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Messias_Feres.png'), linkedin: 'https://www.linkedin.com/in/messiasfcm/', github: 'https://github.com/MessiasFCM' },
      { name: 'Dilvonei Alves', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Dilvonei.jpg'), linkedin: 'https://www.linkedin.com/in/dilvonei-alves-lacerda-05328a228/', github: 'https://github.com/DilvoneiL' },
    ],
  },
  'arche-empresa-junior': {
    title: 'Arche Empresa Junior',
    image: case2,
    fullDescription: 'Site para uma empresa júnior de arquitetura de São João del-Rei, melhorando a gestão de projetos e presença digital.',
    members: [
      { name: 'Membro', role: 'Diretor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
    ],
  },
  'buffet-sonho-real-tiradentes': {
    title: 'Buffet Sonho Real Tiradentes',
    image: case1,
    fullDescription: 'Site expositivo para modernização de um buffet em Tiradentes, focado em aumentar as reservas online.',
    members: [
      { name: 'Membro', role: 'Diretor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
    ],
  },
  'milhas-gerais': {
    title: 'Milhas Gerais',
    image: case4,
    fullDescription: 'Site dinâmico e interativo para o projeto Milhas Gerais da UFSJ, com foco em visibilidade e engajamento.',
    members: [
      { name: 'Membro', role: 'Diretor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
    ],
  },
  'trem-ki-voa-aerodesign': {
    title: 'Trem Ki Voa Aerodesign',
    image: case3,
    fullDescription: 'Site dinâmico e interativo para o projeto de aerodesign da UFSJ, com foco em visibilidade e engajamento.',
    members: [
      { name: 'Membro', role: 'Diretor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
      { name: 'Membro', role: 'Assessor de Projetos' },
    ],
  },
};

const ProjectDetails = () => {
  const { projectId } = useParams();
  const project = projectData[projectId];

  if (!project) {
    return <p>Projeto não encontrado.</p>;
  }

  return (
    <section className={styles.projectDetails}>
      {/* Botão de Voltar para a tela de Cases */}
      <Link to="/cases" className={styles.backButton}>
        ← Voltar para Cases
      </Link>

      <h1 className={styles.title}>{project.title}</h1>
      <div className={styles.title_underline}></div>
      <img src={project.image} alt={project.title} className={styles.projectImage} />
      <p className={styles.description}>{project.fullDescription}</p>

      {project.members && project.members.length > 0 && (
        <>
          <h2 className={styles.membersTitle}>Membros Participantes</h2>
          <div className={styles.title_underline}></div>
          <MemberCarousel members={project.members} />
        </>
      )}
    </section>
  );
};

export default ProjectDetails;

import React from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '../layouts/LinkButton';
/* Assets */
import service1 from '../assets/site.png';
import service2 from '../assets/codigo.png'; 
import service3 from '../assets/dados.png'; 
import service4 from '../assets/consultoria.png';
import service5 from '../assets/ecommerce.png'; 
import service6 from '../assets/mao.png'; 
import service7 from '../assets/blog.png'; 
import img1 from '../assets/img-website.png'; 
import img2 from '../assets/Desafio_Consistente.jpg'
import img3 from '../assets/img-dados.jpg'
import img4 from '../assets/img-consultoria.jpg'
import img5 from '../assets/img-ecommerce.jpg'
import img6 from '../assets/img-portfolio.png'
import img7 from '../assets/img-blog.png'


/* Styles */
import '../styles/index.module.css'; 
import styles from '../styles/pages/services.module.css';

const Services = () => {

  return (
    <section className={styles.success_services_section}>
      <h1>Nossos Serviços</h1>
      <div className={styles.title_underline}></div> {/* Adicionando o risco */}
      <h2>Venha Descobrir Nossos Serviços</h2>
      <p>Explore nossos serviços e veja como podemos ajudar a sua empresa a crescer.</p>
      <div className={styles.service}>
      <img src={img1} alt="websites" className={styles.service_image} />
        <div className={styles.service_text}>
          <h3 className={styles.service_title}>
              <img src={service1} alt="Icon" className={styles.icon} /> Site Institucional
          </h3>
          <p>Desenvolvemos websites modernos e responsivos que não apenas cativam visualmente, mas também proporcionam uma experiência de usuário intuitiva. Nossos sites são personalizados para refletir a identidade única de cada cliente, garantindo uma presença online marcante e eficaz.</p>
        </div>
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
        <div className={styles.service_text}>
        <h3 className={styles.service_title}>
              <img src={service2} alt="Icon" className={styles.icon} /> Sistema Web
          </h3>
          <p>Desenvolvemos sistemas web sob medida para atender às necessidades específicas do seu negócio. Otimize seus processos internos e melhore a eficiência operacional com nossas soluções personalizadas.</p>
        </div>
        <img src={img2} alt="sistemas" className={styles.service_image} />
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
      <img src={img3} alt="dados" className={styles.service_image} />
        <div className={styles.service_text}>
          <h3 className={styles.service_title}>
              <img src={service3} alt="Icon" className={styles.icon} /> Dados
          </h3>
          <p>Oferecemos consultoria em ciência de dados e automação de processos, capacitando sua empresa a tomar decisões estratégicas com base em dados concretos.</p>
          
        </div>
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
        <div className={styles.service_text}>
        <h3 className={styles.service_title}>
              <img src={service4} alt="Icon" className={styles.icon} /> Consultoria
          </h3>
          <p>Fornecemos consultoria especializada em desenvolvimento web, com foco na criação e otimização de websites e sistemas web personalizados.</p>
        </div>
        <img src={img4} alt="consultoria" className={styles.service_image} />
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
      <img src={img5} alt="e-commercer" className={styles.service_image} />
        <div className={styles.service_text}>
          <h3 className={styles.service_title}>
              <img src={service5} alt="Icon" className={styles.icon} /> E-commerce
          </h3>
          <p>Construímos lojas virtuais que combinam design atraente e funcionalidades poderosas. Nossas soluções em e-commerce otimizam a experiência de compra, aumentam a conversão e impulsionam suas vendas online.</p>
        </div>
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
        <div className={styles.service_text}>
        <h3 className={styles.service_title}>
              <img src={service6} alt="Icon" className={styles.icon} /> Portfólio
          </h3>
          <p>Desenvolvemos portfólios online atraentes e funcionais, que destacam seus melhores projetos e habilidades. Com um design envolvente e uma navegação intuitiva, ajudamos você a impressionar clientes e oportunidades.</p>
        </div>
        <img src={img6} alt="portfolio" className={styles.service_image} />
      </div>
      <hr className={styles.divider} />

      <div className={styles.service}>
      <img src={img7} alt="blog" className={styles.service_image} />
        <div className={styles.service_text}>
          <h3 className={styles.service_title}>
              <img src={service7} alt="Icon" className={styles.icon} /> Blog
          </h3>
          <p>Criamos blogs personalizados, focados em design moderno e fácil navegação. Com nossas soluções, você pode compartilhar conteúdo de forma profissional, atrair seu público e expandir sua presença online.</p> 
        </div>
      </div>  
      <div>
        <LinkButton to="/cases" text="Explorar cases"/>
      </div>
    </section>
  );
};

export default Services;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* Import das páginas */
import Home from './pages/Home';
import Company from "./pages/Company";
import Cases from "./pages/Cases";
import Services from "./pages/Services";
import ProjectDetails from './pages/ProjectDetails';
import Budget from "./pages/Budget";

/* Import dos Componentes */
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Error404 from './components/Error404';
import Container from "./layouts/Container"; 
import SpecialFooter from './components/FooterBudget';

function App() {
  return (
    <Router>
      <Navbar />
      <Container customClass='min-height'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/cases" element={<Cases />} />
          <Route path="/services" element={<Services />} />
          <Route path="/project-details/:projectId" element={<ProjectDetails />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Container>
      <Routes>
          <Route path="/budget" element={<SpecialFooter />} />
          <Route path="*" element={<Footer />} /> {/* Default Footer */}
        </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
